<template>
  <div>
  </div>
</template>

<script>
import abstractForm from '../../mixin/index';

export default {
  mixins: [abstractForm],
  name: 'CreateOrUpdate',
  data() {
    return {
      // value: ''
    };
  },
  methods: {},
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
//
</style>
